import { Component, OnInit, AfterViewInit, OnDestroy, Input, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { DatePipe, getLocaleDateFormat, FormatWidth } from "@angular/common";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { Actions, ofType } from "@ngrx/effects";
import { WfmTextboxComponent, ValidationResult } from "src/app/components/wfm-textbox/wfm-textbox.component";
import { AppActionTypes, SaveAsPlan, CheckPlanNameExists, SaveAsPlanSuccess } from "src/app/store/actions";
import {Subject} from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { PlanHelper } from "src/app/helpers/plan-helper";
import { WfmModalComponent } from "src/app/components/wfm-modal/wfm-modal.component";
import { DialogOptions } from "src/app/common/dialog-options";
import { FormGroup, FormBuilder } from "@angular/forms";
import {TenantService} from '../../../tenant.service';

@Component({
  selector: "plan-save-as",
  templateUrl: "./plan-save-as.component.html",
  styleUrls: ["./plan-save-as.component.scss"],
  providers: [DatePipe]
})
export class PlanSaveAsComponent implements OnInit, AfterViewInit, OnDestroy {

  planId: string;
  entityType: string;
  entityName: string;
  ltfcstName: String;
  entityId: String;
  planStartDate: any;
  planEndDate: any;

  private planName: string;
  private _enableAccept = false;
  private _isLoading = false;
  private _subscriptions=[];
  private subject: Subject<string>;

  saveAsForm: FormGroup;

  @Input() inEntityType: String;
  @Input() inEntityName: String;
  @Input() inLtfcstName: String;

  private static fieldRequiredMsg;

  @ViewChild("planNameInput") planNameEl: WfmTextboxComponent;

  constructor(public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private store: Store<any>,
    private action$: Actions,
    public modalService: NgbModal,
    public tenantService: TenantService) {
      this.subject = new Subject();
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.planNameEl.validators.push(this.planNameValidator.bind(this));
    this.subject.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(data => {
        this.checkDupName(data);
      });
  }

  ngOnInit(): void {
    PlanSaveAsComponent.fieldRequiredMsg = this.translate.instant("field.required.msg");

    if (!this.isCXOne) {
      this.inEntityType = this.translate.instant("plan.label.entity." + this.entityType.toLocaleLowerCase());
      this.inEntityName = this.entityId + " " + this.entityName;
    }
    this.buildForecastDateRange();

    this.saveAsForm = this.fb.group({});
    this._subscriptions.push(this.action$.pipe(ofType(AppActionTypes.SaveAsPlanReceived)).subscribe(this.saveAsReceivedHandler));
    this._subscriptions.push(this.action$.pipe(ofType(AppActionTypes.SaveAsPlanFailed)).subscribe(this.saveAsFailedHandler));
    this._subscriptions.push(this.action$.pipe(ofType(AppActionTypes.CheckPlanNameExistsReceived)).subscribe(this.planNameExistsReceivedHandler));
    this._subscriptions.push(this.action$.pipe(ofType(AppActionTypes.CheckPlanNameExistsFailed)).subscribe(this.planNameExistsFailedHandler));
  }

  doOnInput() {
  }

  private buildForecastDateRange() {
    let dateShortFormat = "M/d/yy";
    if (this.translate.currentLang) {
      dateShortFormat = getLocaleDateFormat(this.translate.currentLang, FormatWidth.Short);
    }

    let startDate = "";
    let endDate = "";
    let fcLabel = this.ltfcstName;

    if (this.planStartDate !== null && this.planEndDate !== null) {
      startDate = this.datePipe.transform(new Date(this.planStartDate), dateShortFormat, "UTC +0000");
      endDate = this.datePipe.transform(new Date(this.planEndDate), dateShortFormat, "UTC +0000");
    }

    if (startDate !== "" && endDate !== "") {
      fcLabel = fcLabel + ` (${startDate}` + " - " + `${endDate})`;
    }

    this.inLtfcstName = fcLabel;
  }

  setPlanName(name) {
    if (name != this.planName) {
        // disable this flag until the plan name has been verified
      this._enableAccept = false;
      this.subject.next(name);
      this.planName = name;
    }
  }

  // check for duplicate plan name
  checkDupName(name) {
    if (name.length > 0 && PlanHelper.planNameRegEx.test(this.planName)) {
      console.debug("Checking plan name: " + name);
      this.store.dispatch(new CheckPlanNameExists({ planName: name }));
    } else {
      this._enableAccept = false;
      this.planNameEl.serverSideErrMsgs = this.planNameEl.serverSideErrMsgs.filter(msg => msg != this.translate.instant("plan.not.unique"));
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  submit() {
    this._isLoading = true;
    this._enableAccept = false;

    this.store.dispatch(new SaveAsPlan({ planId: this.planId, planName: this.planName }));
  }

  planNameExistsReceivedHandler = (action: any) => {
    console.debug("Plan name exists: " + action.payload.exists);

    // only proceed if the requested name check matches the current value in the text box
    if (action.payload.planName === this.planName) {
      if (action.payload.exists === true) {
        this._enableAccept = false;
        this.planNameEl.serverSideErrMsgs.push(this.translate.instant("plan.not.unique"));
      } else {
        this._enableAccept = true;
        this.planNameEl.serverSideErrMsgs = this.planNameEl.serverSideErrMsgs.filter(msg => msg != this.translate.instant("plan.not.unique"));
      }
    }
  };

  planNameExistsFailedHandler = async (action: any) => {
    console.log("Plan name exists failure, HTTP status: " + action.payload.status);
    this._enableAccept = false;
    this.dismiss();
    const done = ()=>{};
    const dialogOpt:DialogOptions = new DialogOptions();
    dialogOpt.titleKey = "generic.err.title";
    dialogOpt.messageKey = "unrecoverable.err.msg";
    dialogOpt.msgType = "error";
    dialogOpt.confirmLabel = "btn.close";
    dialogOpt.showCancel = false;
    WfmModalComponent.showModalMessage(dialogOpt, this.modalService).then(done, done);
  };

  configureModalInstance(modalRef: NgbModalRef) {
    modalRef.componentInstance.titleKey = this.translate.instant("plan.save.as.ack.title.dlg");
    modalRef.componentInstance.messageKey = this.translate.instant("plan.save.as.success");
    modalRef.componentInstance.msgType = "info";
    modalRef.componentInstance.showCancel = false;
    modalRef.componentInstance.okDisable = false;
    modalRef.componentInstance.confirmLabel = "btn.ok.label";
  }

  saveAsReceivedHandler = (action: any) => {
    // console.log("Save As plan name: " + action.payload.body.planName);

    // close save as dialog
    this.dismiss();

    const modalRef = this.modalService
      .open(WfmModalComponent, {
        centered: true,
        windowClass: "b-info wfm-modal",
        container: "div.dialog-container"
      });

    this.configureModalInstance(modalRef);

    modalRef.result.then((result) => {
        // ok clicked
        this.store.dispatch(new SaveAsPlanSuccess(action.payload));
      }, (reason) => {
        // clicked elsewhere
        this.store.dispatch(new SaveAsPlanSuccess(action.payload));
      });
  };

  saveAsFailedHandler = async (action: any) => {
    if (action.payload.status === 409) {
      this._isLoading = false;
      this._enableAccept = false;
      this.planNameEl.serverSideErrMsgs.push(this.translate.instant("plan.not.unique"));
    } else if (action.payload.status === 500 || action.payload.status === 401) {
      this.dismiss();
      const done = ()=>{};
      const dialogOpt:DialogOptions = new DialogOptions();
      dialogOpt.titleKey = "generic.err.title";
      dialogOpt.messageKey = "unrecoverable.err.msg";
      dialogOpt.msgType = "error";
      dialogOpt.confirmLabel = "btn.close";
      dialogOpt.showCancel = false;
      WfmModalComponent.showModalMessage(dialogOpt, this.modalService).then(done, done);
    } else {
      this._isLoading = false;
      this._enableAccept = true;
    }
  };

  planNameValidator(name: string) {
    if (name.length < 1) {
      return new ValidationResult(PlanSaveAsComponent.fieldRequiredMsg);
    } else if (PlanHelper.planNameRegExStartOrEndWithSpecialCharacter.test(name)) {
      return new ValidationResult(this.translate.instant("plan.name.error.special.character.start.end"));
    } else if (!PlanHelper.planNameRegEx.test(name)) {
      return new ValidationResult(this.translate.instant("plan.name.error.disallowed.characters"));
    } else {
      return new ValidationResult("");
    }
  }

  get enableAccept() {
    return this._enableAccept;
  }

  get isLoading() {
    return this._isLoading;
  }

  get isCXOne() {
    return this.tenantService.isCXOne;
  }
}
