import {Injectable} from '@angular/core';
import {filter, map, tap} from 'rxjs/operators';
import {Observable, fromEvent} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';

const NICE_MFE_ESP = 'NICE_MFE_BRIDGE_ESP';
const NICE_MFE_CXONE = 'NICE_MFE_BRIDGE_CXONE';

export enum MessageType {
  HANDSHAKE = 'handshake',
  NAVIGATION = 'navigation',
  NAVIGATION_SUCCESS = 'navigation_success',
  NAVIGATION_REJECTED = 'navigation_rejected',
  AUTH = 'auth',
  ERROR = 'error',
  REFRESH_TOKEN = 'refresh_token',
  REFRESH_TOKEN_ERROR = 'refresh_token_error',
  ACTIVITY = 'user_activity'
}

export interface MFEMessage {
  source: string;
  type: MessageType;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class MFEBridgeService {
  targetFrame = window.opener || window.parent;
  isReady = false;

  constructor(private router: Router) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.isReady) {
        this.sendMessage(event.url, MessageType.NAVIGATION);
      }
    });
  }

  initiateHandshake(): void {
    this.sendMessage(null, MessageType.HANDSHAKE);
  }

  sendMessage(data: any, type: MessageType) {
    let message: MFEMessage = {
      source: NICE_MFE_ESP,
      type,
      data
    };
    console.log("ESP sending message",message);
    this.targetFrame.postMessage(message, '*');
  }

  receiveMessages(): Observable<MFEMessage> {
    return fromEvent<MessageEvent<MFEMessage>>(window, 'message').pipe(
      map(event => event.data),
      filter(data => data.source === NICE_MFE_CXONE),
      tap(data => this.handleMessage(data))
    );
  }

  private handleMessage(data: MFEMessage) {
    console.log("ESP Handling Bridge Message",data);
    if(data.type === MessageType.AUTH) {
      if(this.isReady==false){
        this.isReady = true;
      }
    }

  }
}
