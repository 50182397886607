import { v4 as uuid } from 'uuid';

export class PlanHelper {

    public static readonly planNameRegEx = /(^[a-zA-Z0-9]+[a-zA-Z0-9-()#<>:;\s]*[a-zA-Z0-9]+$)|^[a-zA-Z0-9]{1}$/;
    public static readonly planNameRegExStartOrEndWithSpecialCharacter = /^[-()#<>:;\s]|[-()#<>:;\s]$/;

    public static PLAN_SESSIONS:string = 'planSessions';

    public static isSessionExist(planId:string) {
      const planSessions = localStorage.getItem(PlanHelper.PLAN_SESSIONS);
      let planSessionsMap = {};
      if (planSessions) {
        planSessionsMap = JSON.parse(planSessions);
      }
      let planSessionId: string = planSessionsMap[planId];
      return !!planSessionId;
    }

    public static getPlanDetailSessionId(planId: string): string {

        const planSessions = localStorage.getItem(PlanHelper.PLAN_SESSIONS);
        let planSessionsMap = {};
        if (planSessions) {
            planSessionsMap = JSON.parse(planSessions);
        }
        let planSessionId:string = planSessionsMap[planId];
        if (!planSessionId) {
            planSessionId = uuid();
            planSessionsMap[planId]= planSessionId;
            const jsonText = JSON.stringify(planSessionsMap);
            localStorage.setItem(PlanHelper.PLAN_SESSIONS, jsonText);
        }

        return planSessionId;
    }

    public static removePlanDetailSessionId(planId:any):void {
      const planSessions = PlanHelper.getPlanSessions();
      if(!planSessions){
        return;
      }

      let planSessionId:string = planSessions[planId];
      if (planSessionId) {
        delete planSessions[planId];
        const jsonText = JSON.stringify(planSessions);
        localStorage.setItem(PlanHelper.PLAN_SESSIONS, jsonText);
      }
    }

    public static getPlanSessions(){
        const planSessions = localStorage.getItem(PlanHelper.PLAN_SESSIONS);
        if(planSessions){
            return JSON.parse(planSessions);
        }
        return null;
    }

}
